<template lang="pug">
.email-preferences
  b-loading(:is-full-page="true" :active.sync="isLoading" :can-cancel="false")
  .container.content-wrapper
    .columns
      .column.is-6.is-offset-3
        h1.title Email Preferences
        b-message(title="You're offline" type="is-warning" :closable="false" v-if="!networkOnLine" data-test="offline-instruction")
          | You're currently offline. You'll need to connect to the internet
          | to continue.
    .columns(v-if="networkOnLine")
      .column.is-6.is-offset-3
        b-message(title="About" type="is-info" aria-close-label="Close message")
          p
            | You can adjust how frequently you receive certain types of
            | emails from our service here or opt out entirely. Note that
            | we will always send transactional emails, such as password
            | resets.
        b-message(title="Error" type="is-warning" aria-close-label="Close message" v-if="error") {{ error }}
    .columns(v-if="networkOnLine && hasLoaded")
      .column.is-6.is-offset-3
        .box
          .content
            strong Remind me to update my net worth:
            br
            br
            .field(v-for="option in reminderOptions" :key="option.value")
              b-radio(v-model="form.remindAfter" name="remindAfter" :native-value="option.value")
                | {{option.name}}
            b-button.is-fullwidth.is-medium.is-primary(:loading="isProcessing" @click="savePreferences")
              | Save Preferences
  about-footer
</template>

<script>
import firebase from 'firebase/app'
import { mapState } from 'vuex'
import AboutFooter from '@/components/AboutFooter'
import reminderOptions from '@/misc/reminder-options'

export default {
  components: {
    AboutFooter
  },
  data() {
    return {
      email: null,
      token: null,
      reminderOptions,
      originalState: {
        remindAfter: null
      },
      form: {
        remindAfter: null
      },
      error: null,
      isLoading: false,
      hasLoaded: false,
      isProcessing: false
    }
  },
  computed: {
    ...mapState('app', ['networkOnLine', 'isPrerendering'])
  },
  watch: {
    '$route.query.token': {
      handler(value) {
        if (!value && !this.isPrerendering) {
          this.$router.push({ name: 'general_settings' })
          return
        }
        this.isLoading = true
        if (this.isPrerendering) {
          return
        }
        firebase
          .functions()
          .httpsCallable('readUnsubscribeToken')(value)
          .then(({ data }) => {
            this.isLoading = false
            const { isValid, email, remindAfter } = data
            if (!isValid) {
              this.error =
                "We can't seem to load your account unfortunately. Try signing in and going to your settings page instead."
              return
            }
            this.hasLoaded = true
            this.email = email
            this.token = value
            this.originalState.remindAfter = remindAfter
            this.form.remindAfter = remindAfter
            // Immediate unsubscribe support
            if (
              this.$route.query.unsubscribe === '1' &&
              remindAfter !== 'never'
            ) {
              this.$buefy.toast.open('Unsubscribing...')
              this.form.remindAfter = 'never'
              this.savePreferences()
            }
          })
          .catch(error => {
            this.isLoading = false
            this.error = error.message
          })
      },
      immediate: true
    }
  },
  methods: {
    savePreferences() {
      if (this.form.remindAfter === this.originalState.remindAfter) {
        return
      }
      this.isProcessing = true
      this.error = null
      firebase
        .functions()
        .httpsCallable('writeEmailPreference')({
          ...this.form,
          token: this.token
        })
        .then(() => {
          this.isProcessing = false
          this.$buefy.toast.open('Your preferences have been saved.')
          this.originalState = { ...this.form }
        })
        .catch(error => {
          this.isProcessing = false
          this.error = error.message
        })
    }
  },
  metaInfo: {
    title: 'Email Preferences'
  }
}
</script>

<style lang="sass" scoped>
.email-preferences
  flex: 1 1 auto
  display: flex
  flex-direction: column
.content-wrapper
  flex-grow: 1
  padding: 2rem 1rem 1rem
  width: 100%
</style>
